@import "./variables.module.scss";

.master-container {
    height: calc(100vh - 75px);
}

.isolated.master-container {
    height: 100vh;
    height: 100svh;
}

.player-view {
    max-width: $max-width;
    margin: $navbar-height auto 0 auto;
    height: calc(100% - #{$mobile-buffer});

    .left-panel {
        width: 230px;
    }

    .right-panel {
        width: 300px;
    }

    .right-panel-close {
        position: absolute;
        top: 65px;
        right: 10px;
        z-index: 3;
    }

    .main-panel-viewport {
        width: calc(100% - 540px);
    }

    .main-panel-viewport.fullscreen {
        width: calc(100% - 240px);
    }

    .bottom-menu {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 80px;
        z-index: 99999999999999999;

        .ui.menu {
            margin: 0;
            z-index: 2;
            position: relative;
        }

        .bottom-panel-opener {
            position: absolute;
            text-align: center;
            top: -40px;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 15px 30px;
            width: 80%;
            border-radius: 20px 20px 0 0;
            display: flex;
            justify-content: space-between;
        }
    }
}
.dark-mode .qd-bottom-menu {
    filter: saturate(50%);
}
.single-course.player-view {
    .left-panel {
        width: 75px;
    }

    .right-panel {
        width: 300px;
    }

    .main-panel-viewport {
        width: calc(100% - 390px);
    }

    .main-panel-viewport.fullscreen {
        width: calc(100% - 145px);
    }

    .bottom-menu {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 80px;
        z-index: 99999999999999999;
    }
}

@media (orientation: landscape) {
    .master-container {
        height: calc(100vh - #{$navbar-desktop-height});
    }

    .player-view {
        margin: $navbar-desktop-height auto;
        padding: 9px 0 0 0;
    }

    .single-course.player-view {
        margin: $navbar-desktop-height auto;
        padding: 0;
    }
}

@media (orientation: landscape) and (min-width: 1440px) {
    .player-view {
        .right-panel {
            width: 350px;
        }

        .main-panel-viewport {
            width: calc(100% - 590px);
        }
    }
}

.single-course-mobile.player-view {
    .ui.menu {
        background: transparent;
        border: none !important;
        margin-top: 10px !important;
        justify-content: center;
    }

    .ui.menu .item {
        padding: 0;
    }

    .ui.attached.menu:not(.vertical) .item {
        align-items: end;
    }

    .ui.menu .item::before {
        width: 0 !important;
    }

    .mobile-view-tab {
        position: relative;
        background: transparent !important;
        border: none !important;
        padding: 2.5px !important;
        height: calc(100vh - 220px);
        overflow: auto;
    }

    .mobile-tab-course-img {
        width: 128px;
        height: 96px;
        border-radius: 10px 10px 0 0;
        padding: 5px 5px 0 5px;
        margin-right: 2px;
        overflow: hidden;

        .mobile-tab-course-img-inner {
            border-radius: 10px 10px 0 0;
        }
    }

    .footer-btn {
        margin: 0 2px;
        height: 90px;
        border-radius: 10px 10px 0 0;
    }

    .footer-btn-content {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        top: 50%;
        transform: translate(0, -40%);
        font-size: 0.8em;
    }

    .footer-btn .icon {
        font-size: 2em;
        margin-bottom: 5px;
    }
}

.welcome-kit {
    margin-top: 10px;

    .ui.menu {
        background: rgba(255, 255, 255, 0.4);
        border: none !important;
        margin-top: 10px !important;

        .active.item {
            background: rgba(255, 255, 255, 0.2);
        }
    }

    .ui.attached.segment {
        background: transparent !important;
        border: none !important;
    }

    .slick-slider {
        ul.slick-dots {
            bottom: -30px;

            li {
                width: 10px;
                height: 10px;
            }
        }
    }
}

.slide-container {
    width: 100%;
    height: calc(100% - 40px);
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 1px 1px 5px 1px #000000;
}

@media screen and (orientation: landscape) {
    .slide-container:not(.fullScreen) {
        margin: 30px auto;
        height: calc(100% - 90px);
        max-width: 360px;
    }
}

.slide-rim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 10px #000000;
    pointer-events: none;
}

.alt-flip-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 80%;
    transform: translate(-50%);
    font-size: 18px;
    line-height: 18px;
    padding: 10px 30px;
    background: #454545;
    color: #ffffff;
    cursor: pointer;
    z-index: 100;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -1px 5px 1px #121212;
    transition: 0.5s;
    text-transform: uppercase;

    &:hover {
        background: #121212;
        font-size: 19px;
        transition: 0.5s;
    }
}

@media screen and (orientation: portrait) {
    .flip-leaderboard {
        position: fixed;
        bottom: 75px;
        padding: 10px 7.5px 10px 15px;
        z-index: 999999;
    }
}

.root-layout-header {
    left: 15px;
    bottom: 0;
    border-radius: 5px 5px 0 0;
    background: #ffffffdd;
    padding: 10px 15px;
}

.tabbed-home .ui.menu:not(.vertical) .item {
    width: 50%;
    justify-content: space-evenly;
    // background: #cccccc;
}

.lang-dropdown {
    border-radius: 15px;
}

.lang-dropdown .ui.icon.button,
.lang-dropdown .ui.icon.buttons .button {
    margin: 0;
    padding: 9px 9px 9px 14px;
    background: transparent;
}
