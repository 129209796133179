html {
    background: #ffffff;
    height: 100%;
    height: 100vh;
    height: 100svh;
}

body {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: "Roboto", sans-serif;
    background: #efefef !important;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (orientation: landscape) {
    html {
        background: #bdbdbd;
    }

    body {
        box-shadow: 0 0 50px 1px #666666;
    }
}

html.dark-mode {
    background: #333333 !important;
    color: #ffffff;
}

body.dark-mode {
    background: #333333 !important;
    color: #ffffff;
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* ================================================= */
/* Header style h1, h2, h3, h4, h5, h6*/
/* ================================================= */
h1 {
    font-weight: 400;
    font-size: 3.5em;
    margin: 0;
    padding: 0;
}

h2 {
    font-weight: 300;
    font-size: 3em;
    margin: 0;
    padding: 0;
}

h3 {
    font-weight: 400;
    font-size: 2em;
    margin: 0;
    padding: 0;
}

h4 {
    font-weight: 400;
    font-size: 1.5em;
    margin: 0;
    padding: 0;
}

h5 {
    font-weight: 700;
    font-size: 1.12em;
    margin: 0;
    padding: 0;
}

h6 {
    font-weight: 700;
    font-size: 0.75em;
    margin: 0;
    padding: 0;
}

/* ================================================= */
/* Scroll Bar Definitions */
/* ================================================= */

/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
    margin-left: 5px !important;
    width: 5px !important;
}

::-webkit-scrollbar-track {
    background: transparent !important;
}

::-webkit-scrollbar-thumb {
    background: #bdbdbd !important;
    border-radius: 10px !important;
    height: 50px !important;
}

/* Works on Firefox */
/* Note: Firefox does not support customizing the scrollbar width */
* {
    scrollbar-width: thin !important;
    scrollbar-color: #bdbdbd transparent !important;
}

/* Works on Internet Explorer */
/* Note: Internet Explorer requires a different approach using the "ms" prefix */
* {
    -ms-overflow-style: scrollbar;
    scrollbar-face-color: #bdbdbd !important;
    scrollbar-track-color: transparent !important;
}

/* ================================================= */
/* Font Style Definitions */
/* ================================================= */
.prifont {
    font-family: "Roboto";
}

.secfont {
    font-family: "Oswald";
}

.qt-lbl {
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
}

.qt-sm {
    font-style: normal;
    font-weight: 400;
    font-size: 0.7em;
}

.qt-mn {
    font-style: normal;
    font-weight: 400;
    font-size: 0.5em;
}

.qt-utn {
    font-style: normal;
    font-weight: 400;
    font-size: 0.6em;
}

/* ================================================= */
/* Box Shadow Definitions */
/* ================================================= */
.qt-shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* ================================================= */
/* Color Definitions */
/* ================================================= */
.qt-bg-midnight {
    background: #121212;
}

.qt-fg-midnight {
    color: #121212;
}

.qt-bg-charcoal {
    background: #454545;
}

.qt-fg-charcoal {
    color: #454545;
}

.qt-bg-half {
    background: #666666;
}

.qt-fg-half {
    color: #666666;
}

.qt-bg-fade {
    background: #aaaaaa;
}

.qt-fg-fade {
    color: #aaaaaa;
}

.qt-bg-ash {
    background: #dddddd;
}

.qt-fg-ash {
    color: #dddddd;
}

.qt-bg-smoke {
    background: #e8e8e8;
}

.qt-fg-smoke {
    color: #e8e8e8;
}

.qt-bg-vapor {
    background: #efefef;
}

.qt-fg-vapor {
    color: #efefef;
}

.qt-bg-ivory {
    background: #f5f5f5;
}

.qt-fg-ivory {
    color: #f5f5f5;
}

.qt-bg-white {
    background: #f5f5f5;
}

.qt-fg-white {
    color: #f5f5f5;
}

.qt-bg-primary-100 {
    background: #ffbf00;
}

.qt-fg-primary-100 {
    color: #ffbf00;
}

.qt-bg-primary-60 {
    background: #ffbf0099;
}

.qt-fg-primary-60 {
    color: #ffbf0099;
}

.qt-bg-primary-30 {
    background: #ffbf004d;
}

.qt-fg-primary-30 {
    color: #ffbf004d;
}

.qt-bg-primary-5 {
    background: #ffbf000d;
}

.qt-fg-primary-5 {
    color: #ffbf000d;
}

.qt-bg-danger-100 {
    background: #ad2929;
}

.qt-fg-danger-100 {
    color: #ad2929;
}

.qt-bg-danger-80 {
    background: #d97575;
}

.qt-fg-danger-80 {
    color: #d97575;
}

.qt-bg-danger-20 {
    background: #ad292980;
}

.qt-fg-danger-20 {
    color: #ad292980;
}

.qt-bg-success-100 {
    background: #c1dc9e;
}

.qt-fg-success-100 {
    color: #c1dc9e;
}

.qt-bg-amplayfier-red {
    background: #ed6e6e;
}

.qt-fg-amplayfier-red {
    color: #ed6e6e;
}

.qt-bg-amplayfier-blue {
    background: #222a35;
}

.qt-fg-amplayfier-blue {
    color: #222a35;
}

.qt-bg-amplayfier-purple {
    background: #6d3655;
}

.qt-fg-amplayfier-purple {
    color: #6d3655;
}

.qt-bg-amplayfier-yellow {
    background: #ffbf00;
}

.qt-fg-amplayfier-yellow {
    color: #ffbf00;
}

.qt-bg-gamiquo-blue {
    background: #00153e;
}

.qt-fg-gamiquo-blue {
    color: #00153e;
}

/* ================================================= */
/* Common Definitions */
/* ================================================= */

.qt-bordered-left {
    border-left: 3px solid #ffbf00;
}

.qt-rounded-top {
    border-radius: 10px 10px 0 0 !important;
}

.qt-clickable {
    cursor: pointer;
}

.qt-cleared-box:after {
    clear: both;
    display: table;
    content: "";
}

.slide-container figure.image {
    margin: 1em 0 !important;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Tooltip text */
.tooltip .tooltip-text {
    visibility: hidden;
    min-width: 200px;
    background-color: #454545;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 15px;
    position: absolute;
    z-index: 1;
    bottom: 50%;
    /* Position to the right of the text */
    left: 125%;
    transform: translateY(50%);
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltip-text::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent #454545 transparent transparent;
    text-align: left;
}

/* Show the tooltip */
.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.ui.dropdown>.dropdown.icon {
    display: inline;
}

.dark-mode .ui.tabular.menu .item:not(.active) {
    color: #f5f5f5;
}

.dark-mode .ui.tabular.menu .item:not(.active):hover {
    color: #ffffff;
}

.dark-mode .ui.segment {
    background: #454545;
    border: 1px solid #666666;
}

.dark-mode .ui.statistic .value, .dark-mode .ui.statistic .label {
    color: #f5f5f5;
}