@import "./variables.module.scss";

// ===================================
// Browser Bar Styles
// ===================================
.browser-bar-header {
    background: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    top: 95px;
    height: 50px;
    border-top-right-radius: 15px;
    padding: 15px 25px 15px 15px;
}

.browser-bar-resync {
    top: 105px;
    right: 10px;

    i {
        margin: 0 !important;
        padding: 0 !important;
    }
}

// ===================================
// Carousel Styles
// ===================================
.slick-slider {
    .slideItm {
        width: 100%;
        // border-radius: 10px;
        overflow: hidden;
    }
}

.slider-bar {
    height: 2.5px;
    top: 17.5px;
    left: 35px;
    transition: all 0.2s linear;
    border-radius: 15px;

    &:hover {
        height: 5px;
        top: 9px;
    }
}

// ===================================
// Avatar Styles
// ===================================
.avatar-img {
    border-radius: 50%;
    margin: 0 auto;
    box-sizing: border-box;
    vertical-align: middle;
}

.avatar-icon {
    border-radius: 50%;
    margin: 0 auto;
    box-sizing: border-box;
    vertical-align: middle;
}

// ===================================
// Badge Styles
// ===================================

.badge {
    .ring {
        width: 90%;
        height: 0;
        padding-bottom: 90%;
        position: relative;
        border-radius: 50%;
        margin: 0 auto;
    }

    .surface {
        width: 80%;
        height: 0;
        padding-bottom: 80%;
        position: absolute;
        top: 10%;
        left: 10%;
        border-radius: 50%;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 7em;
    }
}

.small .badge {
    width: 33%;
    max-width: 128px;
    display: inline-block;
    font-size: 0.33em;
    cursor: pointer;
}

// ===================================
// Certificate Styles
// ===================================

.certificate {
    height: 0;
    padding-bottom: 75%;

    .cert-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .cert-content-container {
        position: absolute;
        z-index: 2;
        width: 90%;
        height: 0%;
        left: 5%;
        top: 7%;
        background: radial-gradient(
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0)
        );
        border-radius: 20px;
        padding-bottom: 65%;
    }

    .cert-title {
        position: absolute;
        z-index: 3;
        top: 20%;
        left: 50%;
        width: 90%;
        text-align: center;
        transform: translate(-50%, 0);
        text-transform: uppercase;
        font-size: 2em;
        font-family: Oswald;
    }

    .cert-leadLine {
        position: absolute;
        z-index: 3;
        width: 80%;
        text-align: center;
        top: 33%;
        left: 10%;
        font-size: 1.25em;
    }

    .cert-learner {
        position: absolute;
        z-index: 3;
        width: 80%;
        top: 45%;
        left: 10%;
        text-align: center;
        padding-bottom: 0.1em;
        border-bottom: 1px solid #666666;
        font-size: 2.2em;
        font-weight: bold;
    }

    .cert-content {
        position: absolute;
        z-index: 3;
        width: 60%;
        top: 60%;
        left: 20%;
        text-align: center;
        font-size: 1.25em;
        line-height: 1;
    }

    .cert-ribbon {
        position: absolute;
        z-index: 3;
        width: 20%;
        border-radius: 5px;
        bottom: 5%;
        right: 6%;
        overflow: hidden;
    }

    .cert-logo {
        position: absolute;
        z-index: 3;
        width: 17%;
        border-radius: 10px;
        bottom: 4%;
        left: 5%;
        overflow: hidden;
    }

    .cert-date {
        position: absolute;
        z-index: 3;
        width: 20%;
        bottom: 9%;
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;
        font-weight: bold;
        font-size: 1.25em;
        line-height: 1;
    }

    .cert-overlay {
        position: absolute;
        z-index: 5;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: radial-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1));

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media screen and (orientation: portrait) {
    .certificate {
        font-size: 0.6em;
    }
}

.small .certificate {
    font-size: 0.4em;
}

// ===================================
// Reward Styles
// ===================================

.reward {
    .reward-overlay {
        position: absolute;
        z-index: 5;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: radial-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1));

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 3em;
        }
    }
}

.small .reward {
    font-size: 0.6em;
    width: 75%;
}

// ===================================
// Leaderboard Styles
// ===================================

.leaderboard {
    padding: 0 30px;
    .podium {
        margin: 10px auto;
        position: relative;
        height: 200px;

        .standing {
            position: absolute;
            width: 80px;
            z-index: 1;
            transform: translate(-50%, 0);

            .top-icon {
                position: absolute;
                top: 29px;
                width: 40px;
                margin: 0 auto;
                position: relative;
                z-index: 1;
                overflow: visible !important;
            }

            .podium-image {
                margin-top: -35px;
            }

            .podium-name {
                font-size: 0.75em;
                line-height: 1.1;
                text-align: center;
                margin-top: 35px;
            }

            .podium-score {
                font-family: "Oswald";
                font-size: 1.25em;
                font-weight: bold;
                text-align: center;
                margin: 5px auto;
            }
        }

        .standing.placed-1 {
            left: 50%;
            z-index: 2;
        }

        .standing.placed-2 {
            top: 10%;
            left: 20%;
        }

        .standing.placed-3 {
            top: 10%;
            left: 80%;
        }
    }

    .top-ten-row {
        margin: 3px auto;
        background: rgba(255, 255, 255, 0.4);
    }

    .top-ten-row.my-row {
        background: rgba(0, 0, 0, 0.4);
        color: #ffffff;
    }
}

// ===================================
// Deck Toolbar Styles
// ===================================

.deck-toolbar {
    position: absolute;
    left: 0;
    bottom: 35px;
    height: 35px;
    width: 100%;
    background: #333333;
    z-index: 99;
    padding-top: 2px;

    .dropdown.icon {
        width: fit-content;
        padding: 0;
        margin: 0 5px 0 5px !important;
    }

    .deck-toolbar-btn {
        margin-top: 5px;

        .ui.dropdown > .dropdown.icon {
            display: none !important;
        }
    }

    .deck-toolbar-control {
        margin-top: -5px;
        font-size: 18px;
        line-height: 18px;
        padding: 5px 5px 7px 5px;
        background: #454545;
        color: #ffffff;
        cursor: pointer;
        z-index: 9;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 -1px 5px 1px #121212;
        transition: 0.5s;
    }

    .deck-toolbar-control.prev {
        border-radius: 0 10px 0 0;
    }

    .deck-toolbar-control.next {
        border-radius: 10px 0 0 0;
    }

    .deck-toolbar-control.disabled {
        background: #999999;
        color: #cccccc;
        cursor: none;
        pointer-events: none;
    }
}

@media screen and (orientation: landscape) {
    .deck-toolbar {
        bottom: 0;
    }
    .deck-toolbar-control {
        &:hover {
            margin-top: -7px;
            transition: 0.5s;
        }
    }
}

// ===================================
// Course Article Styles
// ===================================

.course-article {
    .course-article-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .course-content-container {
        position: absolute;
        z-index: 2;
        width: 95%;
        background: radial-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0));
    }

    .course-article-header {
        margin: 15px;
    }

    .course-article-name {
        margin: 15px;
    }

    .course-article-description {
        padding: 6px 20px;
    }
}

// ===================================
// ScratchCard Canvas Override Styles
// ===================================
.ScratchCard__Container {
    .ScratchCard__Canvas {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        border-radius: 10px;
    }
}

// ===================================
// PhotoSubmission Styles
// ===================================
.photosubmission {
    .camera-icon-uploader {
        width: 211px;
        height: 192px;
    }

    .photosubmission-input-style {
        display: none;
    }
}

// ===================================
// Iframe Styles
// ===================================
.iframe-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

// ===================================
// Podcast Styles
// ===================================
.podcast-reader {
    .audio-box {
        background-image: linear-gradient(120deg, #f5edb7, #11f7b9);

        .audio-player {
            display: inline-block;
            margin: 20% auto;
            padding: 1%;
        }
    }
}

// ===================================
// Poll Reader Styles
// ===================================

.progress-bar-container {
    display: flex;
    align-items: center;
}

.progress-bar-size {
    width: 75%;
    margin-right: 10px;
    /* Add margin-right to adjust spacing */
}

.progress-bar-value {
    color: #686868;
    font-size: 12px;
    text-align: right;
    margin-left: 60px;
    margin-top: -15px;
}

// ===================================
// Checklist Styles
// ===================================
.checklist-reader {
    .checklist-button-group {
        position: absolute;
        bottom: 0%;
        left: 0;
        right: 0;
        top: 85%;
        width: 100%;
        background: #d3d3d3;
    }
}

.html2canvas-container {
    width: 3000px !important;
    height: 3000px !important;
}

// ===================================
// Reel Styles
// ===================================

.portrait-player {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 177.75%;
}

.portrait-player .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

// ===================================
// Accordion Styles
// ===================================

.accordion {
    .panel-container {
        box-shadow: 0px -1px 5px #21181830;
        margin-top: -16px;
    }

    .panel-container:first-of-type {
        margin-top: 0px;
    }
}

.sub-block .ui.styled.accordion .accordion .content,
.sub-block .ui.styled.accordion .content {
    padding: 0 !important;
}

// ===================================
// Product Catalog Styles
// ===================================

.product-catalog-reader {
    .product-name {
        position: sticky;
        padding: 10px 30px;
        z-index: 2;
        border-radius: 10px 10px 0 0;
    }

    .catalog-main-section {
        column-count: 2;
        padding: 10px 0;
    }

    .catalog-main-section.two-divs {
        orphans: 1;
        widows: 1;
    }

    .details-section {
        padding: 0 5px 10px 0px;
    }

    .product-catalog-card {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        display: inline-block;
        margin-bottom: 10px;
    }
}

// ===================================
// Rating Styles
// ===================================
.rating-option {
    display: flex;
    align-items: flex-start;
}

.rating-label {
    flex: 1;
    margin-right: 10px;
    word-break: break-word;
    margin-top: 5px;
}

.rating-box-container {
    display: flex;
    gap: 5px;
    flex-shrink: 0;
}

.rating-box {
    width: 26px;
    height: 27px;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
}

.rating-box.selected {
    background-color: #686868;
    color: #fff;
}

.subtitle {
    display: inline-block;
    border-bottom: 1px solid #aaaaaa;
}

.star-rating {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

// ===================================
// Circular Button Styles
// ===================================
.circular-button {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;
    margin-bottom: 5px;
}

.circular-button.active {
    box-shadow: 0 0 10px 2px #000000;
}

.circular-button.disabled {
    cursor: not-allowed;
}

.circular-ring {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
}

.circular-surface {
    width: 80%;
    height: 0;
    padding-bottom: 80%;
    position: absolute;
    top: 10%;
    left: 10%;
    border-radius: 50%;
}

.circular-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em !important;
}

// ===================================
// Recommendation Button Styles
// ===================================

.recommendation-button {
    padding: 0.1em 4em;
    width: auto;
}

// ===================================
// Profiler Button Styles
// ===================================

.profiler-button {
    padding: 0.1em 4em;
    width: auto;
}

// ===================================
// Guidelines Styles
// ===================================
.guidelines {
    .dont-triangle {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100px;
        height: 100px;
        background: linear-gradient(
            to bottom right,
            #c1dc9e 0%,
            #c1dc9e 50%,
            #f0b7b7 50%,
            #f0b7b7 100%
        );
        align-content: flex-end !important;
        justify-content: flex-end !important;
    }

    .do-triangle {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100px;
        height: 100px;
        background: linear-gradient(
            to bottom right,
            #f0b7b7 0%,
            #f0b7b7 50%,
            #c1dc9e 50%,
            #c1dc9e 100%
        );
        align-content: flex-end !important;
        justify-content: flex-end !important;
    }
}

// ===================================
// Events Styles
// ===================================
.event {
    .event-bg {
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .event-content-container {
        position: absolute;
        z-index: 1;
        top: 0px;
    }
}

// ===================================
// Webinar Styles
// ===================================
.webinar {
    .webinar-bg {
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .webinar-content-container {
        position: absolute;
        z-index: 1;
        top: 0px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        background: radial-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0));

        .webinar-content-container-header {
            position: absolute;
            top: 19px;
        }
    }
}

@media screen and (orientation: portrait) {
    .webinar {
        .webinar-content-container {
            padding: 30px !important;
        }
    }
}

// ===================================
// Downloadable Styles
// ===================================
.downloadable {
    .downloadable-bg {
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .downloadable-content-container {
        position: absolute;
        z-index: 1;
        top: 0px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        background: radial-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0));

        .downloadable-content-container-header {
            position: absolute;
            top: 19px;
        }
    }
}

@media screen and (orientation: portrait) {
    .downloadable {
        .downloadable-content-container {
            padding: 30px !important;
        }
    }
}

// ===================================
// PlayList Button Styles
// ===================================

.playlist-reader {
    width: 102%;

    .image-radius {
        border-radius: 15px;
    }

    .playlist-active-image {
        margin: 0 auto 30px auto;
        box-shadow: 1px 8px 20px 20px #ced6e4;
    }

    .play-icon {
        border: 1px solid grey;
    }

    .list-item {
        cursor: pointer;
        display: flex !important;
        align-items: center;
    }

    //Audio Player Styles
    .rhap_container {
        box-shadow: unset;
    }

    .rhap_additional-controls {
        display: none;
    }

    .rhap_main-controls {
        margin: auto;
    }

    .rhap_volume-controls {
        display: none;
    }

    .rhap_play-pause-button {
        height: 60px;
        width: 46px;
    }

    .rhap_progress-filled,
    .rhap_progress-indicator {
        background-color: #709fdc;
    }
}

// ===================================
// Product GRID Styles
// ===================================

.product-grid-reader {
    .product-grid-name {
        position: sticky;
        padding: 10px 30px;
        z-index: 2;
        margin-top: -32px !important;
        width: fit-content;
    }

    .circular-button-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
    }
}

// ===================================
// DataOverlay Styles
// ===================================
.data-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
}

// ===================================
// Banner Styles
// ===================================
.banner {
    .fade-in-image {
        animation: fadeIn 5s;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }

    .banner-img {
        width: 100%;
        height: 0;
        padding-bottom: 50%;
        position: relative;

        .resp-banner-img-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .banner-title {
            position: absolute;
            bottom: 0;
            left: 0;
            border-top-right-radius: 20px;
            padding: 10px 20px 10px 15px;
            z-index: 2;
        }
    }

    .banner-link {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        border-radius: 50%;

        .icon {
            margin-right: 0;
        }

        &:hover {
            box-shadow: 0 0 20px 1px #ffffff;
            top: 14px;
            right: 14px;
        }
    }

    .dots {
        position: absolute;
        right: 10px;
        bottom: 5px;

        .dot {
            background: #666666;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            margin-right: 6px;
            display: inline-block;
            box-shadow: 0 0 5px 1px #cccccc;
            cursor: pointer;
        }

        .dot.active {
            background: #000000;
            width: 12px;
            height: 12px;
        }
    }
}

// ===================================
// Category Card Styles
// ===================================

.category-card {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 20px;
    position: relative;

    .category-card-img-container {
        width: 100%;
        height: 0;
        padding-bottom: 50%;
        // display: flex;
        overflow: hidden;
        background-size: cover;
        background-position: center;

        img {
            height: 100%;
            width: auto;
            max-width: 110% !important;
            transform: translate(-5%);
        }
    }

    .card-content {
        width: 100%;
        height: 0;
        padding-bottom: 50%;
    }

    .category-progress-radial {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}

// ===================================
// Course Card Styles
// ===================================

.ui.grid > .stackable.stackable.row > .column.course-card-item,
.ui.stackable.grid > .column.grid > .column.course-card-item,
.ui.stackable.grid > .column.row > .column.course-card-item,
.ui.stackable.grid > .column:not(.row).course-card-item,
.ui.stackable.grid > .row > .column.course-card-item,
.ui.stackable.grid > .row > .wide.column.course-card-item,
.ui.stackable.grid > .wide.column.course-card-item {
    margin: 2.5px 0 !important;
    padding: 0 5px !important;
}

.course-card {
    width: 100%;
    height: 95%;
    position: relative;

    .course-card-img-container {
        width: 40%;
        height: 0;
        padding-bottom: 30%;
        // display: flex;
        overflow: hidden;
        background-size: cover;
        background-position: center;

        img {
            height: 100%;
            width: auto;
            max-width: 110% !important;
            transform: translate(-5%);
        }
    }

    .card-content {
        width: 60%;
    }

    .category-progress-radial {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}

// ===================================
// Topic Card Styles
// ===================================

.topic-card {
    .topic-name {
        margin-top: 4px;
    }

    i {
        margin-top: 1px;
        color: #666666;
    }
}

.topic-card.disabled {
    background: #cccccc;
    color: #999999;
    pointer-events: none;
}

// ===================================
// Article Card Styles
// ===================================
.article-carousel {
    .disabled {
        pointer-events: none;
    }
}

.carousel-shortlist .slick-track {
    margin-left: 0 !important;
}

.article-card,
.article-block-card,
.article-viewer,
.portrait-article-card {
    .article-image-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 33.33%;
        overflow: hidden;

        img {
            width: 105%;
        }
    }

    .article-abstract-container {
        color: #ffffff;

        .article-abstract-main-icon {
            position: absolute;
            font-size: 6em;
            top: 10px;
            left: 20px;
            z-index: 2;
            transform: rotate(-30deg);
            color: #000000;

            .icon {
                opacity: 0.6;
                text-shadow: 0 0 100px #000000;
            }
        }

        .article-abstract-back {
            width: 100%;
            height: 0;
            padding-bottom: 33.33%;
            margin-left: 10%;
            overflow: hidden;

            .abstract-row {
                position: relative;
                width: 100%;
                height: 25%;
                opacity: 0.45;
            }

            .abstract-row:nth-child(1) {
                transform: translate(-50%) rotate(45deg);
            }

            .abstract-row:nth-child(2) {
                transform: translate(-25%) rotate(45deg);
            }

            .abstract-row:nth-child(3) {
                transform: translate(0) rotate(45deg);
            }

            .abstract-row:nth-child(4) {
                transform: translate(25%) rotate(45deg);
            }

            .icon {
                position: absolute;
                font-size: 30px;
            }

            .icon:nth-child(8n + 1) {
                left: 0;
            }

            .icon:nth-child(8n + 2) {
                left: 12.5%;
            }

            .icon:nth-child(8n + 3) {
                left: 25%;
            }

            .icon:nth-child(8n + 4) {
                left: 37.5%;
            }

            .icon:nth-child(8n + 5) {
                left: 50%;
            }

            .icon:nth-child(8n + 6) {
                left: 62.5%;
            }

            .icon:nth-child(8n + 7) {
                left: 75%;
            }

            .icon:nth-child(8n + 8) {
                left: 87.5%;
            }

            .row:nth-child(2n) .icon {
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.portrait-article-card {
    padding: 2px;

    .pc-overlay {
        background: rgb(255, 249, 249);
        background: linear-gradient(
            180deg,
            rgba(255, 249, 249, 0) 30%,
            rgba(0, 0, 0, 1) 80%
        );
    }

    .article-abstract-container {
        color: #ffffff;
        height: 0;
        padding-bottom: 133.33%;

        .article-abstract-main-icon {
            position: absolute;
            font-size: 6em;
            top: 20px;
            left: 20px;
            z-index: 2;
            transform: rotate(-30deg);
            color: #000000;

            .icon {
                opacity: 0.6;
                text-shadow: 0 0 100px #000000;
            }
        }

        .article-abstract-back {
            width: 100%;
            height: 0;
            padding-bottom: 133.33%;
            margin-left: 0;
            overflow: hidden;
        }
    }
}

.dark-mode .portrait-article-card img {
    filter: saturate(50%);
}

// ===================================
// Discussion Styles
// ===================================

.discussion-comment-actions {
    display: flex;
    align-items: center;
}

.discussion-like-action {
    display: flex;
    margin-right: 10px;
}

.discussion-like-content {
    display: flex;
    align-items: center;
}

.discussion-like-icon {
    margin-right: 0;
}

.discussion-like-count {
    margin-left: -13px;
}

.comment-form-container {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 10px;
    z-index: 1;
}

//------------------------------------------------------
//Search Bar Styles
//------------------------------------------------------

.search.dark-mode .ui.input input {
    background-color: #454545;
    color: #ffffff;
}

.search-bar {
    position: relative;
    width: calc(100% + 50px);
    transform: translateX(-25px);

    input {
        border-radius: 50px 0px 0px 50px !important;
        // box-shadow: 0 0 1px 1px #cccccc !important;
    }

    .search-action {
        background-color: #999999;
        color: #ffffff;
        padding: 12.5px 12.5px 12px 12.5px !important;
        border-radius: 0px 50px 50px 0px !important;
    }

    .suggestion-list {
        background: #ffffffed;
        border-radius: 5px;
        margin-top: 1%;
        position: absolute;
        width: 100%;
        box-shadow: 0 0 10px 1px #000000;

        .suggestion-list-item {
            padding: 4% !important;
            cursor: pointer;
        }
    }
}

.search-bar.dark-mode {
    input {
        background-color: #454545;
        color: #ffffff;
    }
    .search-action {
        background-color: #666666;
        color: #ffffff;
    }
}

//------------------------------------------------------
// Horizontal Accordion Styles
//------------------------------------------------------

.horizontal-accordion {
    display: flex;
    flex-direction: row;

    .item {
        width: 100%;
        transition: width 0.3s ease;
        cursor: pointer;
        box-sizing: border-box;

        &.expanded {
            width: calc(100% - 10px);
        }
    }
}

// ===================================
// Social Styles
// ===================================

.social-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.post-header {
    display: flex;
}

.profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.post-user p {
    margin: 0;
    font-size: 12px;
    color: #888;
}

.post-content {
    word-wrap: break-word;
}

.post-content a {
    word-break: break-all;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.post-content img {
    max-width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}

.post-content video {
    max-width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}

.post-actions {
}

.comments p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
}

.comment-form {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.comment-form input {
    flex-grow: 1;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
}

.comment-form button {
    border: none;
    background-color: #0077b5;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.comment-form button:hover {
    background-color: #005689;
}

.post-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.follow-button {
    background-color: #0077b5;
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;
    border: none;
    margin-top: -15px;
}

.options-dropdown {
    position: relative;
    margin-top: -15px;
}

.options-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.options-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.options-menu button {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.options-menu button .icon {
    margin-right: 5px;
    color: #0077b5;
}

// ===================================
// Social Uploader Styles
// ===================================
.social-uploader-container {
    background-color: white;
    border-radius: 5px;
    margin-top: 10px;

    .social-uploader-container-tab {
        .ui.secondary.pointing.menu {
            margin-bottom: 0;
        }
    }
}

.social-image-post {
    width: 100%;
    margin-top: 10px;
}

// ===================================
// Format Card Styles
// ===================================
.format-card {
    background-color: #ffffff;

    &:hover {
        background-color: #f5f5f5;
        box-shadow: 0 0 2px 1px #cccccc;
    }
}

// ===================================
// Creator Sidebar Styles
// ===================================
$sidebar-width: 60px;

.creator-sidebar {
    .nav-logo {
        width: $sidebar-width;
        text-align: center;

        img {
            width: calc(100% - 30px);
            margin: 11.5px auto;
        }
    }

    .nav-name {
        position: absolute;
        bottom: 10px;
        text-transform: uppercase;
        font-size: 2em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: $sidebar-width;
        width: 300px;
        transform: rotate(-90deg);
        transform-origin: top left;
        color: #666666;
        pointer-events: none;
    }

    .nav-item {
        width: $sidebar-width;
        font-size: 1.5em;
        text-align: center;
        cursor: pointer;

        div:hover {
            background-color: #666666;
            color: #ffffff;
        }
    }

    .nav-back {
        position: absolute;
        bottom: 0;
        width: $sidebar-width;
        font-size: 1.5em;
        text-align: center;
        cursor: pointer;

        div:hover {
            background-color: #999999;
            color: #ffffff;
        }
    }
}

.inline-edit-icon {
    color: #666666;

    &:hover {
        color: #000000;
    }
}

// ===================================
// Countdown Styles
// ===================================

.quo-countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    font-size: 24px;
    color: #ffffff;
}

.quo-pulse {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: pulse-animation 1s infinite linear;
    z-index: -1;
}

@keyframes pulse-animation {
    0% {
        transform: scale(0.95);
        opacity: 1;
    }

    50% {
        transform: scale(2);
        opacity: 0.65;
    }

    75% {
        transform: scale(3);
        opacity: 0.15;
    }

    100% {
        transform: scale(4);
        opacity: 0;
    }
}

// ===================================
// Creator Course Analytics Styles
// ===================================

.course-analytics-container {
    padding: 5px;
    height: 80px;
    background: "#efefef";
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: scroll;
    width: 100%;
}

.course-analytics-header {
    font-size: 0.9em;
    height: 70px;
    writing-mode: vertical-lr;
    display: inline-block;
    padding: "0 12px";
    background: #dddddd;
    text-align: center;
    margin-right: 5px;
}

.simple-listing-header {
    font-size: 1.1em;
    font-weight: normal;
    line-height: 1.1;
    padding: 7px;
    background: #efefef;
    color: #ffffff;
    min-height: 32px;
}

.simple-list {
    background: #ffffff;
    border-radius: 10px;
    overflow: hidden;
}

.simple-list-content {
    position: relative;
    overflow: auto;
    background: #e5e5e5;
}
